const styles = [
  {value: '', label: 'Picked by AI'},
  {value: 'cartoon', label: 'Cartoon'},
  {value: 'classic', label: 'Classic'},
  {value: 'clinical', label: 'Clinical'},
  {value: 'feminine', label: 'Feminine'},
  {value: 'healthy', label: 'Healthy'},
  {value: 'lifestyle', label: 'Lifestyle'},
  {value: 'masculine', label: 'Masculine'},
  {value: 'modern', label: 'Modern'},
  {value: 'recruitment', label: 'Recruitment'},
  {value: 'sporty', label: 'Sporty'},
  {value: 'vintage', label: 'Vintage'},
];
const themes = [
  {value: '', label: 'Picked by AI'},
  {value: 'offer_led', label: 'Offer Led'},
  {value: 'picture_led', label: 'Picture Led'},
  {value: 'picture_offer', label: 'Picture Offer'},
  {value: 'text_only', label: 'Text Only'},
];

const channels = [
  {value: 'digital', label: 'Digital'},
  {value: 'social', label: 'Social'},
  {value: 'video', label: 'Video'},
  {value: 'print', label: 'Print'},
];

const formatsDigitalOptions = [
  {value: 'billboard', label: 'Billboard 970x250'},
  {value: 'digital_a4', label: 'Digital A4 1200x2000'},
  {value: 'digital_300x300', label: 'Digital 300x300'},
  {value: 'digital_320x320', label: 'Digital 320x320'},
  {value: 'digital_400x400', label: 'Digital 400x400'},
  {value: 'digital_500x500', label: 'Digital 500x500'},
  {value: 'digital_565x565', label: 'Digital 565x565'},
  {value: 'digital_600x315', label: 'Digital 600x315'},
  {value: 'digital_672x352', label: 'Digital 672x352'},
  {value: 'digital_1080x1080', label: 'Digital 1080x1080'},
  {value: 'digital_1080x1920', label: 'Digital 1080x1920'},
  {value: 'digital_1200x900', label: 'Digital 1200x900'},
  {value: 'digital_3466x1811', label: 'Digital 3466x1811'},
  {value: 'digital_4680x8320', label: 'Digital 4680x8320'},
  {value: 'edm_600x1200', label: 'Edm 600x1200'},
  {value: 'fb_newsfeed_wrapper', label: 'FB Newsfeed Wrapper 501x807'},
  {value: 'fb_newsfeed', label: 'FB Newsfeed 1080x1080'},
  {value: 'half_page', label: 'Halfpage 300x600'},
  {value: 'leaderboard', label: 'Leaderboard 728x90'},
  {value: 'mobile_small_banner', label: 'MobileSmallBanner 320x50'},
  {value: 'mpu', label: 'MPU 300x250'},
  {value: 'skyscraper', label: 'Skyscraper 160x600'},
  {value: 'mobile', label: 'Mobile 300x50'},
  {value: 'instagram', label: 'Instagram 1080x1080'},
  {value: 'tablet_banner', label: 'Tablet Banner 468x60'},
  {value: 'twitter', label: 'Twitter 1200x620'},
  {value: 'facebook', label: 'Facebook 1200x627'},
  {value: 'linkedin_social_media', label: 'LinkedIn Social Media 1200x980'},
  {value: 'social_media_story', label: 'Social Media Story 1080x1920'},
  {value: 'adaptive_vertical_image', label: 'Adaptive Vertical Image 960x1200'},
  {value: 'small_skyscraper', label: 'Small Skyscraper 120x600'},
  {value: 'medium_rectangle', label: 'Medium Rectangle 300x200'},
  {value: 'large_mobile_banner', label: 'Large Mobile Banner 320x100'},
  {value: 'mobile_static', label: 'Mobile Static 300x50'},
  {value: 'mobile_small_banner_static', label: 'Mobile Small Banner Static 320x50'},
  {value: 'leaderboard_static', label: 'Leaderboard Static 728x90'},
  {value: 'tablet_banner_static', label: 'Tablet Banner Static 468x60'},
  {value: 'small_rectangle', label: 'Small Rectangle 280x130'},
  {value: 'mini_banner', label: 'Mini Banner 300x100'},
  {value: 'large_mobile_banner', label: 'Large Mobile Banner 320x100'},
  {value: 'large_leaderboard', label: 'Large Leaderboard 960x250'},
  {value: 'instagramv1', label: 'Digital Instagram V1 1080x1080'},
  {value: 'instagramv2', label: 'Digital Instagram V2 1080x1080'},
  {value: 'instagramv3', label: 'Digital Instagram V3 1080x1080'},
];

const fetchTemplatesFromOption = [
  {value: '', label: 'None'},
  {value: 'team', label: 'Team'},
  {value: 'combined', label: 'Combined'},
];
export {styles, themes, channels, formatsDigitalOptions, fetchTemplatesFromOption};
